import { ChangeDetectionStrategy, Component } from '@angular/core';
import { LoadingService } from '@core/services/loading.service';
import { AsyncPipe } from '@angular/common';
import { BlockUIModule } from 'primeng/blockui';

@Component({
  selector: 'app-spinner',
  standalone: true,
  templateUrl: './spinner.component.html',
  styleUrl: './spinner.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    BlockUIModule,
  ],
})
export class SpinnerComponent {

  public isLoading$ = this._loadingService.loading$.asObservable();

  constructor(
    private readonly _loadingService: LoadingService,
  ) {
  }

}
