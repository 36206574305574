import { ChangeDetectionStrategy, Component } from '@angular/core';
import { notificationAnimation } from '@core/constants/animations';
import { NotificationService } from '@core/services/notification.service';
import { AsyncPipe } from '@angular/common';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-notification',
  standalone: true,
  templateUrl: './notification.component.html',
  styleUrl: './notification.component.scss',
  animations: [notificationAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    AsyncPipe,
    ButtonModule,
  ],
})
export class NotificationComponent {

  public notification$ = this._notificationService.getNotification();

  constructor(
    private readonly _notificationService: NotificationService,
  ) {
  }

  public onHide(): void {
    this._notificationService.hideNotification();
  }

}
