import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { regularFontSizeAnimation, smallFontSizeAnimation } from '@core/constants/animations';

@Component({
  selector: 'app-sidebar-footer',
  templateUrl: './sidebar-footer.component.html',
  styleUrl: './sidebar-footer.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [regularFontSizeAnimation, smallFontSizeAnimation],
})
export class SidebarFooterComponent {

  @Input({ required: true })
  public isSidebarOpen!: boolean;
  @Input()
  public userData: any | null = null;

  @Output()
  public logout = new EventEmitter<void>();

  public get initials(): string {
    return `${this.userData?.firstName?.charAt(0) || ''}${this.userData?.lastName?.charAt(0) || ''}`.toLocaleUpperCase();
  }

}
