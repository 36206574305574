import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AuthModule as OAuthModule, LogLevel } from 'angular-auth-oidc-client';
import { environment } from '@env/environment';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule,
    OAuthModule.forRoot({
      config: {
        authority: environment.authServer,
        redirectUrl: location.origin,
        clientId: environment.clientId,
        responseType: 'code',
        scope: environment.scope,
        postLogoutRedirectUri: location.origin,
        triggerAuthorizationResultEvent: true,
        renewUserInfoAfterTokenRenew: true,
        startCheckSession: true,
        silentRenew: true,
        logLevel: LogLevel.Warn,
        useRefreshToken: true,
        secureRoutes: ['/api', environment.apiUrl],
        ignoreNonceAfterRefresh: true,
      },
    }),
  ],
  exports: [OAuthModule],
  providers: [],
})
export class AuthModule {
}
