<div class="sidebar" [class.closed]="!isOpen">
  <div class="sidebar__header">
    <div class="sidebar__header--logo">
      <img class="logo-icon"
           src="./assets/icons/logo-icon.svg"
           alt="logo" />
      @if (isOpen) {
        <img class="logo-text"
             src="./assets/icons/logo-text.svg"
             alt="logo" />
      }
    </div>
    @if (isOpen) {
      <p class="sidebar__header--text" @smallFontSizeAnimation>ELINOTIFY</p>
    } @else {
      <p class="sidebar__header--text">EN</p>
    }

  </div>
  <div class="sidebar__trigger">
    <i class="pi sidebar__trigger--icon" aria-hidden="true"
       [ngClass]="isOpen ? 'pi-arrow-left' : 'pi-arrow-right'"
       (click)="toggleSidebar.emit()">
    </i>
  </div>
  <div class="sidebar__links">
    <app-sidebar-item [itemText]="'Groups'"
                      icon="pi pi-users"
                      link="/groups"
                      [isSidebarOpen]="isOpen">
    </app-sidebar-item>
    <hr class="sidebar__links-line" />
    @if (isTabAvailable('administration')) {
      <app-sidebar-item [itemText]="'Administration'"
                        icon="pi pi-folder"
                        link="/administration"
                        [isSidebarOpen]="isOpen">
      </app-sidebar-item>
    }
    @if (isTabAvailable('tg-users')) {
      <app-sidebar-item [itemText]="'Telegram IDs'"
                        icon="pi pi-list"
                        link="/tg-users"
                        [isSidebarOpen]="isOpen">
      </app-sidebar-item>
    }
    @if (isTabAvailable('settings')) {
      <app-sidebar-item [itemText]="'System Settings'"
                        icon="pi pi-cog"
                        link="/settings"
                        [isSidebarOpen]="isOpen">
      </app-sidebar-item>
    }
  </div>
  <app-sidebar-footer [userData]="userData$ | async"
                      [isSidebarOpen]="isOpen"
                      (logout)="onLogoutEvent()">
  </app-sidebar-footer>
</div>
