import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { smallFontSizeAnimation } from '@core/constants/animations';
import { UserModel } from '@app/shared/models/user.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss',
  animations: [smallFontSizeAnimation],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent {

  @Input()
  public isOpen: boolean = true;

  @Output()
  public toggleSidebar = new EventEmitter<void>();

  constructor(
    private readonly _authService: AuthService,
  ) {
  }

  public get userData$(): Observable<UserModel | null> {
    return this._authService.userData$;
  }

  public isTabAvailable(tab: string): boolean {
    switch (tab) {
    case 'administration':
      return this._authService.hasGroupsForAdministration;
    case 'settings':
    case 'tg-users':
      return this._authService.isSuperAdmin;
    default:
      return false;
    }
  }

  public onLogoutEvent(): void {
    this._authService.logout();
  }

}
