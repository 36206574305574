import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdministrationGuard } from '@core/guards/administration.guard';
import { SuperAdminGuard } from '@core/guards/super-admin.guard';

const routes: Routes = [
  {
    path: 'groups',
    loadChildren: () => import('./modules/groups-list/groups-list.module').then((m) => m.GroupsListModule),
  },
  {
    path: 'administration',
    loadChildren: () => import('./modules/administration/administration.module').then((m) => m.AdministrationModule),
    canActivate: [AdministrationGuard],
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule),
    canActivate: [SuperAdminGuard],
  },
  {
    path: 'tg-users',
    loadChildren: () => import('./modules/tg-users-list/tg-users-list.module').then((m) => m.TgUsersListModule),
    canActivate: [AdministrationGuard],
  },
  {
    path: '',
    redirectTo: 'groups',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRouting {
}
