import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { regularFontSizeAnimation } from '@core/constants/animations';

@Component({
  selector: 'app-sidebar-item',
  templateUrl: './sidebar-item.component.html',
  styleUrl: './sidebar-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [regularFontSizeAnimation],
})
export class SidebarItemComponent {

  @Input() public link: string | null = null;
  @Input() public icon = '';
  @Input() public itemText = '';
  @Input() public isSidebarOpen = false;

}
