@if (notification$ | async; as notification) {
  <div class="notification__wrapper"
       aria-hidden="true"
       @notificationAnimation>
    <div class="notification">
      @if (!notification?.isError) {
        <img class="notification__icon"
             src="./assets/icons/success-icon.svg"
             alt="success" />
      } @else {
        <img class="notification__icon"
             src="./assets/icons/error-icon.svg"
             alt="error" />
      }
      <div class="notification__content">
        @if (notification.title) {
          <span class="notification__title">
          {{ notification?.title }}
        </span>
        }
        @if (notification.text) {
          <p class="notification__text">
            {{ notification.text }}
          </p>
        }
      </div>
      <i class="pi pi-times notification__close-icon" aria-hidden="true"
         (click)="onHide()">
      </i>
    </div>
  </div>
}
