import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from '@app/shared/components/sidebar/sidebar.component';
import { SidebarItemComponent } from './sidebar-item/sidebar-item.component';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { SidebarFooterComponent } from './sidebar-footer/sidebar-footer.component';
import { ButtonModule } from 'primeng/button';


@NgModule({
  declarations: [
    SidebarComponent,
    SidebarItemComponent,
    SidebarFooterComponent,
  ],
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    ButtonModule,
  ],
  exports: [
    SidebarComponent,
  ],
})
export class SidebarModule {
}
