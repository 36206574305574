@if (userData) {
  <div class="sidebar__footer"
       [class.closed]="!isSidebarOpen">
    <p class="user-name" @regularFontSizeAnimation>
      @if (isSidebarOpen) {
        {{ userData.fullName }}
      } @else {
        {{ initials }}
      }
    </p>
    @if (isSidebarOpen) {
      <p class="user-email" @smallFontSizeAnimation>
        {{ userData.email }}
      </p>
      <p-button class="sidebar__footer__long-btn"
                [outlined]="true"
                severity="danger"
                @smallFontSizeAnimation
                (onClick)="logout.emit()">
        <img class="logout-img" src="./assets/icons/logout.svg" alt="logout" />
        <span>Log Out</span>
      </p-button>

    } @else {
      <button (click)="logout.emit()"
              class="sidebar__footer__short-btn">
        <img src="./assets/icons/logout.svg" alt="logout" />
      </button>
    }
  </div>
}


