import { HttpEvent, HttpHandler, HttpInterceptor as Interceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '@env/environment';

@Injectable()
export class UrlInterceptorService implements Interceptor {

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const url: string = `${!req.url.includes(environment.authServer) ? environment.apiUrl : ''}${req.url}`;

    const apiReq = req.clone({ url });
    return next.handle(apiReq);
  }

}
