@if ((initLoad$ | async) === false && (userData$ | async)) {
  <div class="notify-app">
    <app-sidebar [isOpen]="isSidebarOpen"
                 (toggleSidebar)="sidebarHandler()">
    </app-sidebar>
    <div class="notify-app__content" [class.sidebar-opened]="isSidebarOpen">
      <router-outlet></router-outlet>
    </div>
    <app-notification></app-notification>
  </div>
}
<app-spinner></app-spinner>
