import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRouting } from '@app/app.routing';
import { CommonModule } from '@angular/common';
import { AuthModule } from '@core/auth/auth.module';
import { SidebarModule } from '@app/shared/components/sidebar/sidebar.module';
import { SpinnerComponent } from '@app/shared/components/spinner/spinner.component';
import { NotificationComponent } from '@app/shared/components/notification/notification.component';
import { InterceptorsModule } from '@core/interceptors/interceptors.module';
import { DialogService } from 'primeng/dynamicdialog';

@NgModule({
  declarations: [AppComponent],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    InterceptorsModule,
    AuthModule,
    AppRouting,
    SidebarModule,
    SpinnerComponent,
    NotificationComponent,
  ],
  providers: [DialogService],
  bootstrap: [AppComponent],
})
export class AppModule {
}
