import { Component, OnInit } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { Observable } from 'rxjs';
import { UserModel } from '@app/shared/models/user.model';
import { OidcSecurityService } from 'angular-auth-oidc-client';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {

  public isSidebarOpen = false;

  constructor(
    private readonly _authService: AuthService,
    private readonly _oidcSecurityService: OidcSecurityService,
  ) {
  }

  public ngOnInit(): void {
    this._oidcSecurityService.checkAuth()
      .subscribe(({ isAuthenticated }) => {
        if (!isAuthenticated) {
          this._oidcSecurityService.authorize();
          return;
        }
        this._authService.loadUserProfile();
      });
  }

  public get initLoad$(): Observable<boolean> {
    return this._authService.initLoad$;
  }

  public get userData$(): Observable<UserModel | null> {
    return this._authService.userData$;
  }

  public sidebarHandler(): void {
    this.isSidebarOpen = !this.isSidebarOpen;
  }

}
